import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Logs.module.css';
import { useParams } from 'react-router-dom';
import { fetchSections, getAllSections } from '../../redux/logsSlice';
import Loader from '../Loader/Loader';

const Logs = () => {
    const dispatch = useDispatch();
    const { companyId } = useParams();
    const [currentCompanyId, setCurrentCompanyId] = useState(companyId);
    const [selectedSection, setSelectedSection] = useState(1);
    const { logsData, status, sectionsData } = useSelector((state) => state.logs);
    const [sectionName, setSectionName] = useState("");
    const [sectionTitle, setSectionTitle] = useState("")

    useEffect(() => {
        dispatch(getAllSections());
        dispatch(fetchSections({ companyId: currentCompanyId, sectionId: selectedSection }));
    }, [selectedSection, dispatch]);


    useEffect(() => {
        const selectedSectionData = sectionsData.find(section => section.sectionId === selectedSection);
        if (selectedSectionData) {
            setSectionName(selectedSectionData.sectionTitle);
            setSectionTitle(selectedSectionData.sectionHeading)
        }
    }, [sectionsData, selectedSection]);

    const formatDate = (dateString) => {
        if (!dateString) {
            return ''; // Return an empty string if the date string is null
        }
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    return (
        <div className={styles.mainLogs}>
            <div className={styles.sectionDropdown}>
                <h2>{sectionName}: <p style={{ fontSize: "22px" }}>{sectionTitle}</p></h2>

                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className={styles.sectionBtn}>
                        {sectionName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {sectionsData && sectionsData.map((section, index) => (
                            <Dropdown.Item onClick={() => setSelectedSection(section.sectionId)} key={index}>{section.sectionTitle}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {status === 'loading' && <Loader />}
            {status === 'succeeded' &&
                <Table bordered>
                    <thead style={{ background: "rgb(207, 148, 79" }}>
                        <tr>
                            <th>S.No</th>
                            <th>Heading Title</th>
                            <th>Username</th>
                            <th>Comment</th>
                            <th>Action:comment</th>
                            <th>Status</th>
                            <th>UpdatedAt</th>
                            <th>UpdatedTime</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(logsData) && logsData.length > 0 ? (
                            logsData?.map((log, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{log.headingTitle}</td>
                                    <td>{log.userName}</td>
                                    <td>{log.comment}</td>
                                    <td>{log.actioncomment}</td>
                                    <td>{log.headingStatus}</td>
                                    <td>{formatDate(log.updateAt)}</td>
                                    <td>{log.updatedAtTime}</td>
                                </tr>
                            ))
                        )
                            :
                            (<tr>
                                <td colSpan="6" style={{textAlign: "center"}} className={styles.centerVertical}>No Logs Data to Show</td>
                            </tr>)}
                    </tbody>
                </Table>
            }
        </div>
    );
};

export default Logs;
