import React from "react";
import { Route, Routes } from "react-router-dom";
import Companies from "../components/Company/Companies";
import Dashboard from "../components/Dashboard/Dashboard";
import Logs from "../components/Logs/Logs";
import GuidanceScreen from "../components/Guidance/Guidance";


const AuthRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Dashboard />} /> */}
      <Route path="/" element={<Companies />} />
      <Route path='/logs/:companyId' element={<Logs />} />
      <Route path='/guidance' element={<GuidanceScreen />} />
      
    </Routes>
  );
};

export default AuthRoutes;
