import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from 'prop-types';

const HoverOverlay = (props) => {
  return (
    <OverlayTrigger
      placement={props.placement}
      overlay={<Tooltip id="text-tooltip">{props.hoverText}</Tooltip>}
    >
      {props.mainInput}
    </OverlayTrigger>
  );
};
HoverOverlay.propTypes = {
  placement:PropTypes.node.isRequired,
  hoverText:PropTypes.node.isRequired,
  mainInput:PropTypes.node.isRequired
}
export default HoverOverlay;