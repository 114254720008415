// signupSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { serviceUrl } from '../service/url';

export const fetchSignupData = createAsyncThunk(
  'signup/fetchSignupData',
  async ({ userName, email, password, companyId }) => {
    try {
      const response = await axios.post(`${serviceUrl}/auth/signup`, {
        userName,
        email,
        password,
        companyId
      });
      return response.data;
    } catch (error) {
      throw Error('Error signing up: ' + error.message);
    }
  }
);

export const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    userData: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSignupData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSignupData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userData = action.payload;
      })
      .addCase(fetchSignupData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default signupSlice.reducer;
