import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQueries, addQueryReply } from '../../redux/querySlice';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import cross_icon from "../../assets/section/cross.svg"
import Form from 'react-bootstrap/Form';
import { jwtDecode } from 'jwt-decode';
import styles from './Guidance.module.css';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { compareAsc, format } from "date-fns";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "react-datepicker/dist/react-datepicker.css"

const Query = ({ reloadQuery }) => {
    const dispatch = useDispatch();
    const queries = useSelector(state => state.query.queries);
    const [showModal, setShowModal] = useState(false);
    const [userData, setUserData] = useState(null)
    const [replyComment, setReplyComment] = useState('');
    const [selectedQueryId, setSelectedQueryId] = useState(null);
    const isLoading = useSelector((state) => state.query.status === 'loading');
    const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));

    useEffect(() => {
        dispatch(fetchQueries(date));
    }, [dispatch, reloadQuery]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const decode = jwtDecode(token)
        setUserData(decode)
    }, [])

    const handleReply = (queryId) => {
        setSelectedQueryId(queryId);
        setShowModal(true);
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return ''; // Return an empty string if the date string is null
        }
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setReplyComment('');
        setSelectedQueryId(null);
    };

    const handleSaveReply = async () => {
        if (replyComment === "") {
            toast.error("Please enter reply comment");
            return;
        }
        const response = await dispatch(addQueryReply({ queryId: selectedQueryId, replyComment }))
        if (response?.payload?.success) {
            toast.success(response.payload.message)
            dispatch(fetchQueries(date));
            handleCloseModal();
        }
        if (response?.error?.message) {
            toast.error(response.error.message);
            handleCloseModal();
        }
    };

    const handleSelectDate = (date) => {
        const formattedDate = format(new Date(date), 'yyyy-MM-dd');
        setDate(date)
        dispatch(fetchQueries(formattedDate));
    };

    return (
        <>
            <div style={{display: "flex", justifyContent: "flex-end", marginTop: "38px"}}>
                <DatePicker selected={date} onChange={(date) => handleSelectDate(date)} className={styles.custom_datepicker_input} />
            </div>
            <Table striped bordered style={{ marginTop: "40px" }}>
                <thead className={styles.answer_table}>
                    <tr>
                        <th>#</th>
                        <th style={{ width: "187px important" }}>Query Comment</th>
                        <th style={{ width: "187px important" }}>Query User Name</th>
                        <th style={{ width: "187px important" }}>Query Date</th>
                        <th style={{ width: "187px important" }}>Query Status</th>
                        <th style={{ width: "187px important" }}>Reply Comment</th>
                        <th style={{ width: "187px important" }}>Replied</th>
                        <th style={{ width: "187px important" }}>Reply Date</th>
                        <th>Reply</th>
                    </tr>
                </thead>
                <tbody>
                    {queries.length > 0 ? (
                        queries.map((query, index) => (
                            <tr key={query?.queryId}>
                                <td>{index + 1}</td>
                                <td>{query?.queryComment}</td>
                                <td>{query?.queryusername}</td>
                                <td>{formatDate(query?.queryDate)}</td>
                                <td>{query?.queryStatus}</td>
                                <td>{query?.replyComment}</td>
                                <td>{query?.replyBy}</td>
                                <td>{formatDate(query?.replyDate)}</td>
                                <td>
                                        {query.queryStatus === 'Pending' && (
                                            <Button onClick={() => handleReply(query.queryId)} style={{ background: "#CF944F", border: "none" }}>Reply</Button>
                                        )}
                                    </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8" style={{textAlign: "center"}}>No queries available</td>
                        </tr>
                    )}
                </tbody>

            </Table>

            {/* Modal for replying to queries */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>Reply to Query</Modal.Title>
                    <img src={cross_icon} alt="cross_icon" aria-label="Close" onClick={handleCloseModal} />
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="replyComment">
                        <Form.Label>Reply Comment</Form.Label>
                        <Form.Control as="textarea" rows={3} value={replyComment} onChange={(e) => setReplyComment(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSaveReply} disabled={isLoading}>
                        {isLoading ? (
                            <Spinner animation="border" size="sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        ) : (
                            'Save Reply'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Query;
