import React, { useState } from 'react';
import styles from './Guidance.module.css';
import { LuUpload } from "react-icons/lu";
import { RxCross1 } from "react-icons/rx";
import helpData from './data';

const Help = () => {
  const [showReply, setShowReply] = useState(false);

  const renderContent = () => {
    return helpData.map((item, index) => (
      <div style={{
        marginBottom: "40px",
      }} key={index}>
        <h4>{index + 1}. {item.title}</h4>
        <div className={styles.helpTextStatus}>
          <div>
            <p>{item.answer}</p>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      {
        showReply && (
          <div className={styles.addQueryDiv}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>Reply</h2>
              <RxCross1 style={{
                marginTop: "5px",
                border: "0.2px solid",
                borderRadius: "50%",
                fontSize: "27px",
                padding: "2px",
                cursor: "pointer"
              }} onClick={() => setShowReply(!showReply)} />
            </div>
            <hr />
            <div>
              <label htmlFor="" className={styles.descLabel}>Type Here</label><br />
              <input type="text" className={styles.descInput} />
            </div>
            <div>
              <label htmlFor="file-upload" className={styles.addFile}>
                Add File
              </label>
              <div className={styles.addFileDesc}>
                <span className={styles.icon}>
                  <LuUpload />
                </span>
                <div>
                  <span className={styles.fileText}>Choose file or drag here</span>
                  <input type="file" id="file-upload" className={styles.addFileInput} />
                </div>

              </div>
            </div>
            <div className={styles.save}>
              <button className={styles.saveBtn}>Save</button>
            </div>
          </div>
        )
      }
      <div className={styles.helpContent}>
        {renderContent()}
      </div>
    </div>
  );
};

export default Help;
