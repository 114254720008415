import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { sendOtp } from '../redux/loginSlice';
import { toast } from 'react-toastify';
import styles from './Signup.module.css';
import login_svg from '../assets/login/login.svg';
import mela_logo from '../assets/login/melaWithText.svg';

const ForgotPassword = () => {
    const [formData, setFormData] = useState({
        email: ''
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(state => state.login.status === 'loading');

    const handleSendOtp = async () => {
        if(formData.email.trim() === ''){
            toast.error("Please enter email");
            return;
        }
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
        if (!isValidEmail) {
            toast.error('Please enter a valid email');
            return;
        }
        try {
            const response = await dispatch(sendOtp(formData));
            if (response?.error?.message) {
                toast.error(response.error.message);
            }
            else if (response.payload.success) {
                toast.success(response.payload.message);
                navigate('/resetpass')
            } else {
                toast.error(response.payload.message);
            }
        } catch (error) {
            console.error("Error sending OTP:", error.message);
            toast.error("Failed to send OTP");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const lowercaseValue = name === 'email' ? value.toLowerCase() : value;
        setFormData((prevState) => ({
            ...prevState,
            [name]: lowercaseValue
        }));
    };


    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <img src={mela_logo} alt="Logo" />
            </div>
            <div className={styles.login_box}>
                <div className={styles.form}>
                    <h2>Welcome to MELA</h2>
                    <label>Email</label>
                    <input type="text" name="email" onChange={handleChange} value={formData.email} style={{textTransform: "lowercase"}}/>
                    <button className={styles.login_button} onClick={handleSendOtp} disabled={isLoading}>
                        {isLoading ? <Spinner animation="border" size="sm" role="status" /> : 'Send OTP'}
                    </button>
                    <p style={{ color: "white", marginTop: "14px" }}>
                        Remembered your password?{' '}
                        <Link to="/" className={styles.signup_link}>
                            Login
                        </Link>
                    </p>
                </div>
                <div className={styles.image}>
                    <img src={login_svg} alt="login_svg" />
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
