import React, { useEffect, useState } from 'react'
import styles from './Guidance.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import cross_icon from '../../assets/section/cross.svg'
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { addQuery, fetchQueries } from '../../redux/querySlice';
import { Spinner } from 'react-bootstrap';

const GuidanceHeader = ({ guidance, setGuidance, setReloadQuery }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [query, setQuery] = useState("");
    const handleClose = () => setShow(false);
    const [roleData, setRoleData] = useState(null);
    const handleShow = () => setShow(true);
    const isLoading = useSelector((state) => state.query.status === 'loading');
    useEffect(() => {
        const decode = jwtDecode(localStorage.getItem('token'));
        setRoleData(decode)
    }, [])

    const handleSaveQuery = async () => {

        if (query === "") {
            toast.error("Please enter a query")
            return
        }
        const response = await dispatch(addQuery(query))
        if (response?.payload?.success) {
            toast.success(response.payload.message)
            setReloadQuery(true)
            dispatch(fetchQueries())
            handleClose();
        }
        if (response?.error?.message) {
            toast.error(response.error.message)
            handleClose();
        }
    }

    return (
        <>
            <div className={styles.totalHelp}>
                <div className={styles.guidanceHeader}>
                    <button
                        className={`${styles.toggleGuidance} ${guidance === 'help' ? styles.active : ''}`}
                        onClick={() => setGuidance('help')}
                    >
                        Help
                    </button>
                    <button className={`${styles.toggleGuidance} ${guidance === 'query' ? styles.active : ''}`}
                        onClick={() => setGuidance('query')}>
                        Query
                    </button>

                </div>

            </div>
            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Query</Modal.Title>
                    <Button variant="link" onClick={handleClose}>
                        <img src={cross_icon} alt="cross_icon" aria-label="Close" />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Write your query</Form.Label>
                        <Form.Control onChange={(e) => setQuery(e.target.value)} as="textarea" rows={3} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSaveQuery} disabled={isLoading}>
                        {isLoading ? (
                            <Spinner animation="border" size="sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        ) : (
                            'Save Query'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal> */}
            {/* {
                showQuery ?
                    <div className={styles.addQueryDiv}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h2>Add Query</h2>
                            <RxCross1 style={{
                                marginTop: "5px",
                                border: "0.2px solid",
                                borderRadius: "50%",
                                fontSize: "27px",
                                padding: "2px",
                                cursor: "pointer"
                            }} onClick={() => setShowQuery(!showQuery)} />
                        </div>
                        <hr />
                        <div>
                            <label htmlFor="" className={styles.descLabel}>Write your query</label><br />
                            <input type="text" className={styles.descInput} />
                        </div>
                        <div className={styles.save}>
                            <button className={styles.saveBtn}>Save</button>
                        </div>
                    </div>
                    : null
            } */}

        </>
    )
}

export default GuidanceHeader