import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { serviceUrl } from '../service/url';


export const fetchCompany = createAsyncThunk(
    'company/fetchCompany',
    async (data) => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    "x-access-token": token
                }
            };
            const response = await axios.post(`${serviceUrl}/admin/company/all`, data, config)
            return response.data.data;
        } catch (error) {
            throw Error('Error fetching company: ' + error.message);
        }
    }
)

export const approveCompany = createAsyncThunk(
    'company/approve',
    async (data) => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    "x-access-token": token
                }
            };
            const response = await axios.post(`${serviceUrl}/admin/company/approve`, data, config)
            return response.data;
        } catch (error) {
            throw Error(error.response.data.message);
        }
    }
)

export const companySlice = createSlice({
    name: 'signup',
    initialState: {
        companyData: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompany.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCompany.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.companyData = action.payload;
            })
            .addCase(fetchCompany.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(approveCompany.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(approveCompany.fulfilled, (state, action) => {
                state.status = 'succeeded';
            }).addCase(approveCompany.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});
export default companySlice.reducer;
