import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "reduxjs-toolkit-persist/lib/storage/session";
import signupSlice from "./signupSlice";
import companySlice  from "./companySlice";
import loginSlice from "./loginSlice";
import logsSlice from "./logsSlice";
import querySlice from "./querySlice";

const signupPersistConfig = {
    key: "signup",
    storage: storageSession,
};
const companyPersistConfig = {
    key: "company",
    storage: storageSession,
}
const loginPersistConfig = {
    key: "login",
    storage: storageSession,
}

const logsPersistConfig = {
    key: "logs",
    storage: storageSession,
}
const queryPersistConfig = {
    key: "query",
    storage: storageSession,
  }


export default combineReducers({
    signup: persistReducer(signupPersistConfig, signupSlice),
    company: persistReducer(companyPersistConfig, companySlice),
    login: persistReducer(loginPersistConfig, loginSlice),
    logs: persistReducer(logsPersistConfig, logsSlice),
    query: persistReducer(queryPersistConfig,querySlice),
});
