import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.css";
import HoverOverlay from "./HoverOverlay";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";
import logout_icon from "../assets/header/logout.svg";
import user_icon from "../assets/header/user_ecllipse.svg";

const Sidebar = ({ setIsSidebarOpen, isSidebarOpen }) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const [email, setEmail] = useState(null)

  const locationObj = useLocation();
  const activePath = locationObj.pathname;

  useEffect(() => {
    const decode = jwtDecode(localStorage.getItem('token'));
    setEmail(decode.email)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setIsSidebarOpen(false);
    localStorage.setItem("sidebaropen", false);
  }, [setIsSidebarOpen]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
    setIsSidebarOpen(true);
    localStorage.setItem("sidebaropen", true);
  }, [setIsSidebarOpen]);

  return (
    <div
      className={classNames(
        styles.sidebar,
        isOpen ? styles.open : styles.closed
      )}
      style={{
        color: "#fff",
        border: `none none none 1px solid 
           "#f3f6f4"
        }`,
      }}
    >
      <ul className={styles.sidebar_ul}>
        <div>
          {isSidebarOpen ? (
            <HoverOverlay
              placement="right"
              hoverText="close"
              mainInput={
                <span className={styles.sidebaropenicon} onClick={handleClose}>
                  <svg
                    viewBox="0 0 320 512"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                    style={{
                      color: "white",
                    }}
                  >
                    <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256l137.3-137.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                  </svg>
                </span>
              }
            />
          ) : (
            <HoverOverlay
              placement="right"
              hoverText="open"
              mainInput={
                <span
                  className={styles.sidebaricon_onisPath}
                  onClick={handleOpen}
                >
                  <svg
                    viewBox="0 0 320 512"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                    className={styles.sidebarcaret_icons}
                    style={{
                      color: "white",
                    }}
                  >
                    <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                  </svg>
                </span>
              }
            />
          )}

          {/* <HoverOverlay
            placement="right"
            hoverText="Dashboard"
            mainInput={
              <Link to={`/`} className={styles.board_navlink}>
                <li
                  className={
                    activePath === `/dashboard`
                      ? styles.active_navlink
                      : styles.sidebar_li
                  }
                  style={{
                    color: "white",
                  }}
                >
                  {isOpen ? (
                    <span className={styles.sidebar_heading}>
                      <svg
                        className={styles.sidebarcaretclose_icon}
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15 27.75V19.25H17.125V27.75H27.75V8.62501H29.875V27.75C29.875 28.3136 29.6511 28.8541 29.2526 29.2526C28.8541 29.6511 28.3136 29.875 27.75 29.875H2.25C1.68641 29.875 1.14591 29.6511 0.747398 29.2526C0.348883 28.8541 0.125 28.3136 0.125 27.75V23.5H2.25V27.75H15ZM25.3126 6.18976L18.9398 12.5648C19.2806 13.2744 19.3469 14.085 19.1257 14.8405C18.9046 15.596 18.4116 16.243 17.7419 16.6567C17.0722 17.0705 16.2731 17.2218 15.4984 17.0815C14.7238 16.9412 14.0285 16.5192 13.5465 15.8968L7.77925 17.8178L6.5 18.2449C6.48659 18.9968 6.20779 19.7197 5.71288 20.2858C5.21798 20.852 4.53884 21.225 3.79552 21.3388C3.05219 21.4526 2.29256 21.3 1.6509 20.9079C1.00924 20.5158 0.526878 19.9094 0.289079 19.196C0.0512795 18.4826 0.0733594 17.7081 0.351415 17.0094C0.629471 16.3107 1.1456 15.7329 1.80855 15.3779C2.47151 15.023 3.2386 14.9139 3.97423 15.0699C4.70986 15.2259 5.36665 15.6369 5.8285 16.2304L12.875 13.8801C12.8845 13.3466 13.0278 12.824 13.2917 12.3602C13.5556 11.8965 13.9317 11.5064 14.3856 11.2257C14.8394 10.9451 15.3565 10.7829 15.8893 10.754C16.4221 10.725 16.9537 10.8304 17.4352 11.0603L23.8102 4.68526C23.4862 4.00676 23.4124 3.23552 23.6019 2.50787C23.7915 1.78023 24.2322 1.14302 24.8461 0.708872C25.46 0.274728 26.2077 0.0716026 26.9569 0.135399C27.7061 0.199196 28.4087 0.525807 28.9404 1.0575C29.4721 1.5892 29.7987 2.29175 29.8625 3.04097C29.9263 3.79019 29.7232 4.53784 29.289 5.15178C28.8549 5.76572 28.2177 6.20641 27.49 6.39596C26.7624 6.58552 25.9911 6.51173 25.3126 6.18763"
                          fill="#CF944F"
                        />
                      </svg>
                      Dashboard
                    </span>
                  ) : (
                    <svg
                      className={styles.sidebarcaretclose_icon}
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15 27.75V19.25H17.125V27.75H27.75V8.62501H29.875V27.75C29.875 28.3136 29.6511 28.8541 29.2526 29.2526C28.8541 29.6511 28.3136 29.875 27.75 29.875H2.25C1.68641 29.875 1.14591 29.6511 0.747398 29.2526C0.348883 28.8541 0.125 28.3136 0.125 27.75V23.5H2.25V27.75H15ZM25.3126 6.18976L18.9398 12.5648C19.2806 13.2744 19.3469 14.085 19.1257 14.8405C18.9046 15.596 18.4116 16.243 17.7419 16.6567C17.0722 17.0705 16.2731 17.2218 15.4984 17.0815C14.7238 16.9412 14.0285 16.5192 13.5465 15.8968L7.77925 17.8178L6.5 18.2449C6.48659 18.9968 6.20779 19.7197 5.71288 20.2858C5.21798 20.852 4.53884 21.225 3.79552 21.3388C3.05219 21.4526 2.29256 21.3 1.6509 20.9079C1.00924 20.5158 0.526878 19.9094 0.289079 19.196C0.0512795 18.4826 0.0733594 17.7081 0.351415 17.0094C0.629471 16.3107 1.1456 15.7329 1.80855 15.3779C2.47151 15.023 3.2386 14.9139 3.97423 15.0699C4.70986 15.2259 5.36665 15.6369 5.8285 16.2304L12.875 13.8801C12.8845 13.3466 13.0278 12.824 13.2917 12.3602C13.5556 11.8965 13.9317 11.5064 14.3856 11.2257C14.8394 10.9451 15.3565 10.7829 15.8893 10.754C16.4221 10.725 16.9537 10.8304 17.4352 11.0603L23.8102 4.68526C23.4862 4.00676 23.4124 3.23552 23.6019 2.50787C23.7915 1.78023 24.2322 1.14302 24.8461 0.708872C25.46 0.274728 26.2077 0.0716026 26.9569 0.135399C27.7061 0.199196 28.4087 0.525807 28.9404 1.0575C29.4721 1.5892 29.7987 2.29175 29.8625 3.04097C29.9263 3.79019 29.7232 4.53784 29.289 5.15178C28.8549 5.76572 28.2177 6.20641 27.49 6.39596C26.7624 6.58552 25.9911 6.51173 25.3126 6.18763"
                        fill="#CF944F"
                      />
                    </svg>
                  )}
                </li>
              </Link>
            }
          /> */}
          <HoverOverlay

            placement="right"
            hoverText="Company"
            mainInput={
              <Link to={{ pathname: "/" }} className={styles.board_navlink}>
                <li
                  className={
                    activePath === "/section_a"
                      ? styles.active_navlink
                      : styles.sidebar_li
                  }
                  style={{
                    color: "white",
                  }}
                >
                  {isOpen ? (
                    <span className={styles.sidebar_heading}>
                      <svg
                        className={styles.sidebarcaret_icon}
                        width="26"
                        height="32"
                        viewBox="0 0 26 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.66699 4.66665V27.3333C1.66699 28.0848 1.9655 28.8054 2.49686 29.3368C3.02821 29.8681 3.74888 30.1666 4.50033 30.1666H21.5003C22.2518 30.1666 22.9724 29.8681 23.5038 29.3368C24.0351 28.8054 24.3337 28.0848 24.3337 27.3333V10.8178C24.3336 10.4404 24.2581 10.0667 24.1117 9.71883C23.9652 9.37094 23.7508 9.05582 23.4808 8.79198L17.1908 2.64081C16.6615 2.12324 15.9506 1.83341 15.2103 1.83331H4.50033C3.74888 1.83331 3.02821 2.13182 2.49686 2.66318C1.9655 3.19453 1.66699 3.9152 1.66699 4.66665Z"
                          stroke="#CF944F"
                          stroke-width="2"
                          stroke-linecap="round"

                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.834 1.83331V7.49998C15.834 8.25143 16.1325 8.9721 16.6638 9.50345C17.1952 10.0348 17.9159 10.3333 18.6673 10.3333H24.334"
                          stroke="#CF944F"
                          stroke-width="2"

                          stroke-linejoin="round"
                        />
                      </svg>
                      Company
                    </span>
                  ) : (
                    <svg
                      className={styles.sidebarcaretclose_icon}
                      width="26"
                      height="32"
                      viewBox="0 0 26 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.66699 4.66665V27.3333C1.66699 28.0848 1.9655 28.8054 2.49686 29.3368C3.02821 29.8681 3.74888 30.1666 4.50033 30.1666H21.5003C22.2518 30.1666 22.9724 29.8681 23.5038 29.3368C24.0351 28.8054 24.3337 28.0848 24.3337 27.3333V10.8178C24.3336 10.4404 24.2581 10.0667 24.1117 9.71883C23.9652 9.37094 23.7508 9.05582 23.4808 8.79198L17.1908 2.64081C16.6615 2.12324 15.9506 1.83341 15.2103 1.83331H4.50033C3.74888 1.83331 3.02821 2.13182 2.49686 2.66318C1.9655 3.19453 1.66699 3.9152 1.66699 4.66665Z"
                        stroke="#CF944F"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.834 1.83331V7.49998C15.834 8.25143 16.1325 8.9721 16.6638 9.50345C17.1952 10.0348 17.9159 10.3333 18.6673 10.3333H24.334"
                        stroke="#CF944F"
                        stroke-width="2"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </li>
              </Link>
            }
          />
          <HoverOverlay

            placement="right"
            hoverText="Guidance"
            mainInput={
              <Link to={{ pathname: "/guidance" }} className={styles.board_navlink}>
                <li
                  className={
                    activePath === "/section_a"
                      ? styles.active_navlink
                      : styles.sidebar_li
                  }
                  style={{
                    color: "white",
                  }}
                >
                  {isOpen ? (
                    <span className={styles.sidebar_heading}>
                      <svg
                        className={styles.sidebarcaret_icon}
                        width="26"
                        height="32"
                        viewBox="0 0 26 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.66699 4.66665V27.3333C1.66699 28.0848 1.9655 28.8054 2.49686 29.3368C3.02821 29.8681 3.74888 30.1666 4.50033 30.1666H21.5003C22.2518 30.1666 22.9724 29.8681 23.5038 29.3368C24.0351 28.8054 24.3337 28.0848 24.3337 27.3333V10.8178C24.3336 10.4404 24.2581 10.0667 24.1117 9.71883C23.9652 9.37094 23.7508 9.05582 23.4808 8.79198L17.1908 2.64081C16.6615 2.12324 15.9506 1.83341 15.2103 1.83331H4.50033C3.74888 1.83331 3.02821 2.13182 2.49686 2.66318C1.9655 3.19453 1.66699 3.9152 1.66699 4.66665Z"
                          stroke="#CF944F"
                          stroke-width="2"
                          stroke-linecap="round"

                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.834 1.83331V7.49998C15.834 8.25143 16.1325 8.9721 16.6638 9.50345C17.1952 10.0348 17.9159 10.3333 18.6673 10.3333H24.334"
                          stroke="#CF944F"
                          stroke-width="2"

                          stroke-linejoin="round"
                        />
                      </svg>
                      Guidance
                    </span>
                  ) : (
                    <svg
                      className={styles.sidebarcaretclose_icon}
                      width="26"
                      height="32"
                      viewBox="0 0 26 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.66699 4.66665V27.3333C1.66699 28.0848 1.9655 28.8054 2.49686 29.3368C3.02821 29.8681 3.74888 30.1666 4.50033 30.1666H21.5003C22.2518 30.1666 22.9724 29.8681 23.5038 29.3368C24.0351 28.8054 24.3337 28.0848 24.3337 27.3333V10.8178C24.3336 10.4404 24.2581 10.0667 24.1117 9.71883C23.9652 9.37094 23.7508 9.05582 23.4808 8.79198L17.1908 2.64081C16.6615 2.12324 15.9506 1.83341 15.2103 1.83331H4.50033C3.74888 1.83331 3.02821 2.13182 2.49686 2.66318C1.9655 3.19453 1.66699 3.9152 1.66699 4.66665Z"
                        stroke="#CF944F"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.834 1.83331V7.49998C15.834 8.25143 16.1325 8.9721 16.6638 9.50345C17.1952 10.0348 17.9159 10.3333 18.6673 10.3333H24.334"
                        stroke="#CF944F"
                        stroke-width="2"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </li>
              </Link>
            }
          />
        </div>
      </ul>
      {isOpen ? (
        <div className={styles.user_info}>
          <div className={styles.user_details}>
            <img src={user_icon} alt="user_img" className={styles.user_icon} />
            <div className={styles.user_text}>
              <p className={styles.user_email}>{email}</p>
            </div>
          </div>
          <div className={styles.logout_details}>
            <img
              src={logout_icon}
              alt="logout"
              className={styles.logout_icon}
            />
            <p className={styles.logout_text} onClick={() => {
                localStorage.clear();
                navigate('/')
                window.location.reload();
              }}>Logout</p>
          </div>
        </div>
      ) : (
        <div className={styles.user_info_close}>
          <div className={styles.user_details_close}>
            <img
              src={user_icon}
              alt="user_img"
              className={styles.user_icon_close}
            />
          </div>
          <div className={styles.logout_details}>
            <img
              src={logout_icon}
              alt="logout"
              
              className={styles.logout_icon_close}
            />
          </div>
        </div>
      )}
    </div>
  );
};

Sidebar.propTypes = {
  setIsSidebarOpen: PropTypes.node.isRequired,
  isSidebarOpen: PropTypes.node.isRequired,
};
export default Sidebar;
