import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import styles from "./Signup.module.css";
import login_svg from '../assets/login/login.svg';
import mela_logo from "../assets/login/melaWithText.svg";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { resetpassword } from '../redux/loginSlice';
import Spinner from 'react-bootstrap/Spinner';

const ResetPassword = () => {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        otp: "",
        password: '',
        confirmPassword: ''
    });
    const [isLoading, setIsLoading] = useState(false); // State to track loading state
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleResetPassword = async () => {
        // Check if any field is empty
        if (!formData.email || !formData.otp || !formData.password || !formData.confirmPassword) {
            toast.error("Please fill all the fields");
            return;
        }
        if (formData.password !== formData.confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
        if (!isValidEmail) {
            toast.error('Please enter a valid email');
            return;
        }

        try {
            setIsLoading(true); // Set loading state to true
            const response = await dispatch(resetpassword(formData));
            if(response?.error?.message){
                toast.error(response.error.message);
            }
            else if (response.payload.success) {
                toast.success(response.payload.message);
                navigate('/');
            } else {
                toast.error(response.payload.message);
            }
        } catch (error) {
            console.error("Reset password failed:", error);
        } finally {
            setIsLoading(false); // Set loading state to false after API call completes
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        const lowercaseValue = name === 'email' ? value.toLowerCase() : value;
        setFormData((prevState) => ({
            ...prevState,
            [name]: lowercaseValue
        }));
    };

    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <img src={mela_logo} alt="Logo" />
            </div>
            <div className={styles.login_box}>
                <div className={styles.form}>
                    <h2>Welcome to MELA</h2>
                    <label>Email</label>
                    <input type="text" name="email" onChange={handleChange} value={formData.email} style={{textTransform: "lowercase"}} />
                    <label>Enter Otp</label>
                    <input type="password" name="otp" onChange={handleChange} value={formData.otp}/>
                    <label>Password</label>
                    <input type={show ? "text" : "password"} name="password" onChange={handleChange} value={formData.password} />
                    <label>Confirm Password</label>
                    <input type={show ? "text" : "password"} name="confirmPassword" onChange={handleChange} value={formData.confirmPassword} />
                    <div className={styles.show_password}>
                        <input type="checkbox" onClick={() => setShow(!show)} />
                        <label>Show Password</label>
                    </div>
                    <button className={styles.login_button} onClick={handleResetPassword}>
                        {isLoading ? <Spinner animation="border" size="sm" role="status" /> : 'Forgot Password'}
                    </button>
                </div>
                <div className={styles.image}>
                    <img src={login_svg} alt="login_svg" />
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
