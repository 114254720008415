import React from "react";
import { Route, Routes } from "react-router-dom";
import Signup from "../Login/Signup";
import Login from "../Login/Login";
import ForgotPassword from "../Login/ForgotPassword";
import ResetPassword from "../Login/ResetPassword";

const UnAuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgotpass" element={<ForgotPassword />} />
      <Route path="/resetpass" element={<ResetPassword />} />
    </Routes>
  );
};

export default UnAuthRoutes;
