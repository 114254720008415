import React, { useState } from "react";
import GuidanceHeader from "./GuidanceHeader";
import Help from "./Help";
import Query from "./Query";
import styles from './Guidance.module.css'


const GuidanceScreen = () => {
    const [guidance, setGuidance] = useState('help')
    const [reloadQuery, setReloadQuery] = useState(false)
   
    return (
        <div className={styles.mainComp}>
            <GuidanceHeader guidance={guidance} setGuidance={setGuidance} setReloadQuery={setReloadQuery}/>
            {guidance === 'help' ? <Help /> : <Query reloadQuery={reloadQuery} />}
        </div>
    )
}

export default GuidanceScreen;