import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { serviceUrl } from '../service/url';

export const fetchLoginData = createAsyncThunk(
    'login/fetchLoginData',
    async (data) => {
        try {
            const response = await axios.post(`${serviceUrl}/admin/auth/login`, data);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                throw Error('User not found');
            } else {
                console.log(error, "This is error message")
                throw Error(error.response.data.message);
            }
        }
    }
);


export const sendOtp = createAsyncThunk(
    'login/sendOtp',
    async (data) => {
        try {
            const response = await axios.post(`${serviceUrl}/admin/auth/otp`, data)
            return response.data;
        } catch (error) {
            if (error.response && (error.response.status === 404 || error.response.status === 400)) {
                console.log(error, "This is error message")
                throw Error(error.response.data.message);
            } else {
                console.log(error, "This is error message")
                throw Error(error.response.data.message);
            }
        }
    }
)

export const resetpassword = createAsyncThunk(
    'login/resetpassword',
    async (data) => {
        try {
            const response = await axios.post(`${serviceUrl}/admin/auth/forgot`, data)
            return response.data;
        } catch (error) {
            console.log(error, "This is an error")
            if (error.response && (error.response.status === 404 || error.response.status === 400)) {
                throw Error(error.response.data.message);
            } else {
                throw Error('Error signing up: ' + error.message);
            }
        }
    }
)

export const loginSlice = createSlice({
    name: 'signup',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLoginData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLoginData.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchLoginData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(sendOtp.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(sendOtp.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(sendOtp.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(resetpassword.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(resetpassword.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(resetpassword.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default loginSlice.reducer;
