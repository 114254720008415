const helpData = [
    {
        "title": "What is BRSR Core?",
        "answer": "BRSR Core, short for Business Responsibility and Sustainability Reporting Core, is a subset of the broader BRSR framework. It encompasses a specific set of Key Performance Indicators (KPIs) or metrics related to 9 Environmental, Social, and Governance (ESG) attributes. This framework is mandated by the Securities and Exchange Board of India (SEBI) for listed entities, facilitating their reporting on ESG performance and the impacts of their value chain."
    },
    {
        "title": "What lead to the development of BRSR Core?",
        "answer": "As of May 10, 2021, the Securities and Exchange Board of India (SEBI) implemented the Business Responsibility and Sustainability Reporting (BRSR) framework. This mandated the top 1,000 listed companies, based on market capitalization, to include sustainability practices in their annual reports. Following recommendations from the ESG Advisory Committee and subsequent public consultation, SEBI identified the need for a more focused approach. Consequently, the Board introduced BRSR Core to provide assurance by listed entities. On July 11, 2023, SEBI launched BRSR Core, streamlining the BRSR framework to emphasize nine key ESG attributes with specific metrics and indicators."
    },
    {
        "title": "Are there any disclosures in BRSR core which were not included in BRSR?",
        "answer": "Considering the relevance to the Indian/Emerging market context, some additional Key Performance Indicators (KPIs) have been introduced for assurance in BRSR Core. These include aspects such as job creation in small towns, the openness of business, and gross wages paid to women. Furthermore, for enhanced global comparability, intensity ratios based on revenue adjusted for Purchasing Power Parity (PPP) have been incorporated, distinguishing these disclosures from those in the original BRSR framework."
    },
    {
        "title": "Which listed entities are required to comply with BRSR Core?",
        "answer": "Listed entities are required to comply with BRSR Core based on a phased approach outlined in a glide path. The mandatory reasonable assurance of the BRSR Core applies to top-listed entities according to the following schedule: Financial Year 2023–24: Top 150 listed entities Financial Year 2024–25: Top 250 listed entities Financial Year 2025–26: Top 500 listed entities Financial Year 2026–27: Top 1000 listed entities"
    },
    {
        "title": "What are the potential challenges in implementing BRSR Core?",
        "answer": "While BRSR Core offers great potential for promoting sustainable and responsible business practices in India, implementing it isn't without its challenges. Here are some key points to consider based on SEBI's framework: Lack of robust data systems: Many companies may lack the systems and resources to systematically collect and analyze ESG data across their value chain. Data gaps in value chain: Obtaining accurate and complete data from numerous suppliers and partners poses a significant challenge, especially for geographically dispersed operations. Finding independent assessors: The pool of qualified and SEBI-approved assurance providers for BRSR Core is still developing, creating potential limitations in choosing the right partner. Integrating with existing processes: Aligning BRSR Core with existing business operations and reporting systems can be challenging, requiring adjustments and potential disruptions. Potential greenwashing concerns: Companies must be wary of greenwashing, ensuring their reported ESG performance accurately reflects their genuine efforts and impacts especially in the value chain."
    },
    {
        "title": "What is the definition of ‘value chain’ for disclosures as per BRSR core?",
        "answer": "The value chain encompasses the primary upstream and downstream partners associated with a listed entity. It collectively represents 75% of the entity's purchases and sales (by value) in both the upstream and downstream segments."
    },
    {
        "title": "What does the term ‘group’ stand for?",
        "answer": "The term “group” means the holding company, subsidiaries, associates and joint ventures of the listed entity."
    },
    {
        "title": "Does the assurance provider need any specific qualification for BRSR Core?",
        "answer": "Assurance of the BRSR Core is profession agnostic and need not require any specific qualification or certification. The Board of the listed entity shall ensure that the assurance provider appointed for assuring the BRSR Core has the necessary expertise for undertaking reasonable assurance in the area of sustainability."
    },
    {
        "title": "Can the internal auditor of a listed entity or its group entities, be appointed as assurance provider for the BRSR Core?",
        "answer": "No, the internal auditor of a listed entity or its group entities, cannot be appointed as the assurance provider for the BRSR Core."
    },
    {
        "title": "Can the statutory auditor of a listed entity be appointed as assurance provider for the BRSR Core?",
        "answer": "Yes, the statutory auditor of a listed entity can be appointed as the assurance provider for the BRSR Core"
    },
    {
        "title": "What activities / services by an assurance provider (for the BRSR Core) can lead to conflict of interest?",
        "answer": "The BRSR Core guidance establishes the fundamental principle that there should not be any conflict of interest with the assurance provider appointed for assuring the BRSR Core. It is recommended that  the assurance provider or any of its affiliates refrains from selling products or offering any non-audit/non-assurance services, including consulting services, to the listed entity or its group entities."
    },
    {
        "title": "What activities are not permissible to be undertaken by an assurance provider for the BRSR Core?",
        "answer": "Activities such as risk management, project management, consulting services, investment advisory, investment banking, design and implementation of information systems, outsourced financial services, actuarial services, and accounting/bookkeeping services are prohibited for the BRSR Core assurance provider for the listed entity or its group entities."
    },
    {
        "title": "List of assurance standards that can be followed by an assurance provider for the BRSR Core?",
        "answer": "Since there are no specific standards suggested by BRSR core, the assurance provider may appropriately use a globally accepted assurance standard on sustainability / non-financial reporting such as the International Standard on Assurance Engagements (ISAE) 3000 or assurance standards issued by The Institute of Chartered Accountants of India (ICAI), such as Standard on Sustainability Assurance Engagements (SSAE) 3000 or Standard on Assurance Engagements (SAE) 3410 “Assurance Engagements on Greenhouse Gas Statements”. Further, disclosure should be made of the assurance standard that is used."
    },
    {
        "title": "How should a listed company, which is not among the top 100, report as per BRSR Core?",
        "answer": "Currently BRSR in has been made mandatory for the top 1,000 listed companies by market capitalization from FY 2022-23 onwards. The listed companies which are not among the top 100 companies can voluntarily choose to follow guidelines and formats provided as BRSR core as part of their annual report and publishing the same on the company’s website."
    },
    {
        "title": "Whether BRSR Core applies to financial services company?",
        "answer": "The principles of business responsibility and sustainability reporting are generic in nature and are applicable to companies from all sectors, i.e. products and services."
    }
]


export default helpData;