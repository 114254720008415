import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { serviceUrl } from '../service/url';

export const fetchSections = createAsyncThunk(
    'logs/fetchSections',
    async (data) => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    "x-access-token": token
                }
            };
            const response = await axios.post(`${serviceUrl}/admin/trail/bySection`, data, config)
            return response.data.data;
        } catch (error) {
            throw Error(error.response.data.message);
        }
    }
)

export const getAllSections = createAsyncThunk(
    'logs/getAllSections',
    async () => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    "x-access-token": token
                }
            };
            const response = await axios.get(`${serviceUrl}/admin/trail/getSection`, config)
            return response.data.data;
        } catch (error) {
            throw Error(error.response.data.message);
        }
    }
)


export const logsSlice = createSlice({
    name: 'logs',
    initialState: {
        logsData: [],
        sectionsData: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSections.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSections.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.logsData = action.payload;
            })
            .addCase(fetchSections.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getAllSections.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllSections.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.sectionsData = action.payload;
            })
            .addCase(getAllSections.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            ;
    },
});
export default logsSlice.reducer;
