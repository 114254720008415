import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Signup.module.css';
import login_svg from '../assets/login/login.svg';
import { Link } from 'react-router-dom';
import mela_logo from '../assets/login/melaWithText.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginData } from '../redux/loginSlice';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';

const Login = () => {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(state => state.login.status === 'loading');

    const handleLogin = async () => {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
        if(formData.email === ''){
            toast.error('Please enter your email address');
            return;
        }
        if(formData.password === ''){
            toast.error('Please enter your password');
            return;
        }
        if(formData.email === '' && formData.password === ''){
            toast.error('Please fill all the fields');
            return;
        }
        if (!isValidEmail) {
            toast.error('Please enter a valid email');
            return;
        }
        

        try {
            const response = await dispatch(fetchLoginData(formData));
            console.log(response, 'response')
            if (response?.error?.message) {
                toast.error(response.error.message);
            } else if (response.payload.success) {
                toast.success(response.payload.message);
                localStorage.setItem('token', response.payload.token);
                window.location.reload();
                navigate('/');
            } else {
                toast.error(response.payload.message);
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const lowercaseValue = name === 'email' ? value.toLowerCase() : value;
        setFormData((prevState) => ({
            ...prevState,
            [name]: lowercaseValue
        }));
    };

    // const isFormValid = ;

    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <img src={mela_logo} alt="Logo" />
            </div>
            <div className={styles.login_box}>
                <div className={styles.form}>
                    <h2>Welcome to MELA</h2>
                    <label>Email</label>
                    <input type="text" name="email" onChange={handleChange} value={formData.email} style={{textTransform: "lowercase"}} />
                    <label>Password</label>
                    <input type={show ? 'text' : 'password'} name="password" onChange={handleChange} value={formData.password} />
                    <div className={styles.show_password}>
                        <input type="checkbox" onClick={() => setShow(!show)} />
                        <label>Show Password</label>
                        <Link to="/forgotpass">Forgot Password?</Link>
                    </div>
                    <button className={styles.login_button} onClick={handleLogin}>
                        {isLoading ? <Spinner animation="border" size="sm" role="status" /> : 'Login'}
                    </button>
                </div>
                <div className={styles.image}>
                    <img src={login_svg} alt="login_svg" />
                </div>
            </div>
        </div>
    );
};

export default Login;
