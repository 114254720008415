import React from 'react';
import styles from './CompanyHeader.module.css';
import { RxCross1 } from "react-icons/rx";

const CompanyHeader = ({ changeStatus, toggled }) => {
    return (
        <div>
            <div className={styles.create_company_btn}>
                <br />

            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "29px" }}>
                <button className={`${toggled ? styles.showOn : styles.showOff} ${styles.btn}`} onClick={changeStatus}>{toggled ? "Approved" : "Not Approved"}</button>
            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Create Company</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ background: 'transparent', border: 'none', fontSize: '21px' }}><RxCross1 /></button>
                        </div>
                        <div className="modal-body">
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseOne"
                                            style={{
                                                background: 'none',
                                                width: '100%',
                                                textAlign: 'left',
                                                paddingLeft: '10px',
                                                border: 'none',
                                                borderBottom: '0.2px solid #c4bfbf',
                                                paddingBottom: '7px',
                                                outline: 'none',
                                                fontSize: "20px"
                                            }}
                                        >
                                            Mandatory Information
                                        </button>

                                    </h2>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div>
                                            <label htmlFor="inputPassword6" className="col-form-label">ID(?)</label>
                                        </div>
                                        <hr />
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto" style={{ width: "50%" }}>
                                                <label htmlFor="inputPassword6" className="col-form-label">Display Name (?)*</label>
                                            </div>
                                            <div className="col">
                                                <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                                            </div>
                                        </div>
                                        <hr />
                                        <div style={{ display: "flex", marginLeft: "247px" }}>
                                            <p>Selected:</p>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto" style={{ width: "50%" }}>
                                                <label htmlFor="inputPassword6" className="col-form-label">Group (?)*</label>
                                            </div>
                                            <div className="col">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault1" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                        73 bit
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault2" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault2">
                                                        Food Sector
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault2" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault2">
                                                        Mela
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <hr />
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto" style={{ width: "50%" }}>
                                                <label htmlFor="inputPassword6" className="col-form-label">Active (?)*</label>
                                            </div>
                                            <div className="col">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault1" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault1">

                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto" style={{ width: "50%" }}>
                                                <label htmlFor="inputPassword6" className="col-form-label">For Internal Use (?)*</label>
                                            </div>
                                            <div className="col">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault1" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault1">

                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item" style={{ marginTop: "20px" }}>
                                    <h2 class="accordion-header">
                                        <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseTwo"
                                            style={{
                                                background: 'none',
                                                width: '100%',
                                                textAlign: 'left',
                                                paddingLeft: '10px',
                                                border: 'none',
                                                borderBottom: '0.2px solid #c4bfbf',
                                                paddingBottom: '7px',
                                                outline: 'none',
                                                fontSize: "20px"
                                            }}
                                        >
                                            Contact Information
                                        </button>

                                    </h2>
                                    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">

                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto" style={{ width: "50%" }}>
                                                <label htmlFor="inputPassword6" className="col-form-label">Primary Contact Name</label>
                                            </div>
                                            <div className="col">
                                                <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto" style={{ width: "50%" }}>
                                                <label htmlFor="inputPassword6" className="col-form-label">Primary Contact Email (use comma for multiple email) (?)*</label>
                                            </div>
                                            <div className="col">
                                                <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyHeader;
