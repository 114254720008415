import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { serviceUrl } from '../service/url';

export const fetchQueries = createAsyncThunk(
  'query/fetchQueries',
  async (date) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          "x-access-token": token
        }
      };
      
      const response = await axios.post(`${serviceUrl}/admin/help/getquery`,{date}, config);
      return response.data.data;
    } catch (error) {
      throw Error('Error fetching queries: ' + error.message);
    }
  }
);

export const addQuery = createAsyncThunk(
  'query/addQuery',
  async (query) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          "x-access-token": token
        }
      };
      const response = await axios.post(`${serviceUrl}/help/query/add`, { queryComment: query }, config);
      return response.data; // Assuming your response structure contains the query data
    } catch (error) {
      throw Error(error.response.data.message);
    }
  }
);

export const addQueryReply = createAsyncThunk(
    'query/addQueryReply',
    async (data) => {
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            "x-access-token": token
          }
        };
        const response = await axios.post(`${serviceUrl}/admin/help/replyquery`, data, config);
        return response.data; // Assuming your response structure contains the query data
      } catch (error) {
        throw Error(error.response.data.message);
      }
    }
)

// Create a slice for queries
export const querySlice = createSlice({
  name: 'query',
  initialState: {
    queries: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQueries.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQueries.fulfilled, (state, action) => {
        state.queries = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchQueries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addQuery.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addQuery.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(addQuery.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(addQueryReply.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addQueryReply.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(addQueryReply.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export default querySlice.reducer;
