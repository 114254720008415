import React, { useEffect, useState } from 'react';
import CompanyHeader from './CompanyHeader';
import styles from './Companies.module.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useNavigate} from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import { FaEye } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompany, approveCompany } from '../../redux/companySlice';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';

const Companies = () => {
  const dispatch = useDispatch();
  const companyData = useSelector((state) => state.company.companyData);
  const [toggled, setToggled] = useState(true);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [show, setShow] = useState(false);
  const [action, setAction] = useState(null)
  const [companyId, setCompanyId] = useState(null);
  const [email, setEmail] = useState(null)
  const [userId, setUserId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.company.status === 'loading');

  const changeStatus = () => {
    setToggled(!toggled);
  };

  const handleAction = (e, companyId, userId, email) => {
    e.preventDefault();
    handleShow()
    setCompanyId(companyId)
    setUserId(userId)
    setEmail(email)
  }

  const handleSave = async(e) => {
    e.preventDefault();
    if(action === null){
      toast.error("Please select an action");
      return;
    }
    const data = {
      companyId: companyId,
      userId: userId,
      email: email,
      isApproved: action
    };
    const response = await dispatch(approveCompany(data));
    if(response?.payload?.status === 200){
      handleClose();
      dispatch(fetchCompany({ pageNumber: currentPageNumber, isApproved: toggled }));
      toast.success(response.payload.message);
    }
    if(response?.error?.message){
      handleClose();
      toast.error(response.error.message);
    }
      
  };


  useEffect(() => {
    dispatch(fetchCompany({ pageNumber: currentPageNumber, isApproved: toggled }));
  }, [toggled, currentPageNumber]);

  const handleView = (companyId) => {
    navigate(`/logs/${companyId}`)
  }

  return (
    <div className={styles.company_header}>
      <CompanyHeader changeStatus={changeStatus} toggled={toggled} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className={styles.dropdownHeader}>
          <Modal.Title>Select Action</Modal.Title>
          <ImCross onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.centeredDropdown}>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic" className={styles.dropdownBtn}>
                {action === null ? "Select Action" : action ? "Approve" : "Reject"}
              </Dropdown.Toggle>

              <Dropdown.Menu className={styles.dropdownMenu}>
                <Dropdown.Item onClick={() => setAction(true)}>Approve</Dropdown.Item>
                <Dropdown.Item onClick={() => setAction(false)}>Reject</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => handleSave(e)} disabled={isLoading} style={{ cursor: action === null ? 'not-allowed' : 'pointer' }}>
            {isLoading ? <Spinner animation="border" size="sm" role="status" /> : "Save"}
          </Button>

        </Modal.Footer>
      </Modal>
      <div className={styles.company_table}>
        <table className="table bordered">
          <thead style={{ background: "#CF944F" }}>
            <tr>
              <th scope="col">Company Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">UserName</th>
              <th scope="col">Created At</th>
              <th scope="col">View Logs</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            {Array.isArray(companyData) && companyData.length > 0 ? (
              companyData.map((company, index) => (
                <tr key={index}>
                  <td className={styles.centerVertical}>{company.companyName}</td>
                  <td className={styles.centerVertical}>{company.email}</td>
                  <td className={styles.centerVertical}>{company.phone ? company.phone : "Not Provided"}</td>
                  <td className={styles.centerVertical}>{company.userName}</td>
                  <td className={styles.centerVertical}>{company.createdAt?.slice(0, 10)}</td>
                  <td style={{ textAlign: "center" }} className={styles.centerVertical}>
                    {company.isApproved.includes('true') ? <FaEye style={{cursor: "pointer"}} onClick={() => handleView(company.companyId)}/> : (
                      <>
                        <p className={styles.approvedBtn} onClick={(e) => handleAction(e, company.companyId, company.userId, company.email)}>Not Approved</p>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className={styles.centerVertical}>No Company Data to Show</td>
              </tr>
            )}
          </tbody>
        </table>

      </div>

      <div className={styles.paginations}>
        <button
          className={styles.previous}
          disabled={currentPageNumber === 0}
          onClick={() => setCurrentPageNumber(currentPageNumber - 1)}
        >
          Previous
        </button>
        <button
          className={styles.next}
          onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Companies;
