import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
} from "redux-persist";
import rootReducer from './rootReducer';

const rootPersistConfig = {
  key: 'melaRoot',
  storage,
}
const persistedReducer = persistReducer(rootPersistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)