import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import styles from "./Signup.module.css";
import login_svg from '../assets/login/login.svg';
import mela_logo from "../assets/login/melaWithText.svg";
import { useDispatch } from 'react-redux';
import { fetchSignupData } from '../redux/signupSlice';

const SignupScreen = () => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
    companyId: ""
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignup = async () => {
    try {
      await dispatch(fetchSignupData(formData)).then((response) => {
        localStorage.setItem('token', response.payload.token)
        window.location.reload();
      })
      navigate("/");
    } catch (error) {
      console.error("Signup failed:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={mela_logo} alt="Logo" />
      </div>
      <div className={styles.login_box}>
        <div className={styles.form}>
          <h2>Welcome to MELA</h2>
          <label>Name</label>
          <input type="text" name="userName" onChange={handleChange} value={formData.userName} />
          <label>Email</label>
          <input type="text" name="email" onChange={handleChange} value={formData.email} />
          <label>Enter Otp</label>
          <input type="password" />
          <label>Password</label>
          <input type={show ? "text" : "password"} name="password" onChange={handleChange} value={formData.password} />
          <label>Confirm Password</label>
          <input type={show ? "text" : "password"} />
          <div className={styles.show_password}>
            <input type="checkbox" onClick={() => setShow(!show)} />
            <label>Show Password</label>
          </div>
          <label>Company Name</label>
          <input type="text" name="companyId" value={formData.companyId} onChange={handleChange} />
          <button className={styles.login_button} onClick={handleSignup}>Sign up</button>
        </div>
        <div className={styles.image}>
          <img src={login_svg} alt="login_svg" />
        </div>
      </div>
    </div>
  );
};

export default SignupScreen;