import MainScreen from './MainScreen/MainScreen';
import { BrowserRouter } from 'react-router-dom';
import UnAuthRoutes from './Routes/UnAuthRoutes'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  let token = localStorage.getItem('token');
  return (
    <>
      <BrowserRouter>
        {token ? (
          <MainScreen/>
        ) : (
          <UnAuthRoutes/>
        )}
      </BrowserRouter>
      <ToastContainer/>
    </>
  );
}

export default App;
