import React, { useState } from "react";
import AuthRoutes from "../Routes/AuthRoutes";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import styles from "./MainScreen.module.css";

const MainScreen = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    return (
        <div>
            <Header isSidebarOpen={isSidebarOpen} />
            <div
                className={`${styles.app} " "  ${!isSidebarOpen ? styles.sidebar_close : styles.sidebar_open
                    }`}
            >
                <Sidebar
                    setIsSidebarOpen={setIsSidebarOpen}
                    isSidebarOpen={isSidebarOpen}
                />
                <main
                    className={
                        window.innerWidth <= 500 && isSidebarOpen === "true"
                            ? styles.main_content2
                            : styles.main_content
                    }
                >
                    <AuthRoutes />
                </main>
            </div>
        </div>
    )
}

export default MainScreen
