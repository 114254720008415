import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import small_mela_logo from "../assets/login/mela.svg";
import mela_logo from "../assets/login/melaWithText.svg";
import cns from "classnames";
import styles from "./Header.module.css";
import { BsGlobe } from "react-icons/bs";

const HeaderScreen = ({ isSidebarOpen }) => {
  return (
    <Navbar
      className={cns(styles.main_header, "fixed-top ")}
      style={{
        backgroundColor: "#141111",
        color: "#CF944F",
        borderBottom: "1px solid #bbb",
      }}
    >
      <div className={styles.navbar_container}>
        <div
          style={{
            color: "white",
          }}
          className={styles.navlink}
        >
          <NavLink
          >
            {isSidebarOpen ? (
              <img src={mela_logo} alt="logo" className={styles.mela_logo} />
            ) : (
              <img
                src={small_mela_logo}
                alt="logo"
                className={styles.small_mela_logo}
              />
            )}
          </NavLink>
        </div>
        <div className={styles.company_heading}>MELA</div>
        <div className={styles.nav_user_details}>
          <div className="dropdown" id="myParent">
            <button
              className={
                "btn  btn-transparent dropdown-toggle " + styles.btn_design
              }
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <BsGlobe /> Eng
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
              <button className="dropdown-item" type="button">
                English
              </button>
              <button className="dropdown-item" type="button">
                Another
              </button>
              <button className="dropdown-item" type="button">
                Something
              </button>
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default HeaderScreen;
